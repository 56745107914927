import { useLocation } from "react-router-dom"
import Player from "./player/Player";

export default ()=>{
    const location =useLocation();
    const {asset}=location.state;
    console.log(asset);

    // Need to find proper URL type based on browser
    // for example on chrome DASH is used and HLS is used for other browsers
    const playbackUrl = asset.Videos && asset.Videos.length > 0 ? asset.Videos[0].Url : asset.VideoPlaybackUrl;
  
    return <>
    <Player src={playbackUrl} asset={asset}/>
    </>
}