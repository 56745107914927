import React, { useState } from 'react';
import { utility } from '../../../utility/utility';
import { LOCAL_STORAGE_KEY, OTTROUTETYPE, OTT_POSTER_TYPE, VODTYPE } from '../../../constant';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useNavigate } from 'react-router-dom';
import crown from '../../../images/crown.png';

const AssetCard = ({ index, ottAsset, positionTop, setPositionTop, timeout }) => {

    const navigate = useNavigate();

    const isFree = ottAsset.VodTypes?.SID == VODTYPE.FREE;
    const canPlay = isFree || utility.getValue(LOCAL_STORAGE_KEY.user)?.PaymentPlan?.length > 0 ? true : false ?? false;

    const { ref, focused } = useFocusable({
        focusKey: index,
        onEnterRelease: () => {

            switch (ottAsset.routeType) {
                case OTTROUTETYPE.Page:
                    var pages = utility.getValue('pages');
                    var page_id = ottAsset.page_id;
                    let page = pages.find(p => p._id === page_id);
                    if (page) {
                        navigate('/', { state: { page: page } })
                    }
                    break;
                case OTTROUTETYPE.Player:
                    if (canPlay) {
                        navigate('/liveTv', { state: { asset: ottAsset } })
                    } else {
                        navigate('/login');
                    }
                    break;
                case OTTROUTETYPE.PI_Page:
                    // navigate('/' + ottAsset.Title.toString().replace(' ', '_'), { state: { asset: ottAsset, ottSetting: ottSetting } })
                    navigate('/' + ottAsset.Title.toString().replace(' ', '_'), { state: { asset: ottAsset, isMobile: false } })
                    break;
                default:
                    if (canPlay) {
                        navigate('/liveTv', { state: { asset: ottAsset } })
                    } else {
                        navigate('/login');
                    }
                    break;
            }
        },
        onFocus: ({ y, ...rest }) => {
            document.getElementById('searchRightSideContainer').scrollTo({
                top: y - 15,
                behavior: "smooth",
            });

            if (ref.current.getBoundingClientRect().y < 115) {
                setPositionTop(106)
            } else {
                if (timeout) {
                    clearTimeout(timeout);
                } else {
                    timeout = setTimeout(() => {
                        setPositionTop(ref.current.getBoundingClientRect().y);
                    }, 700);
                }
            }

        }

    });

    return <FocusContext.Provider value='search'>
        {focused && positionTop != 0 && <div> <img src={utility.getPosterUrl(ottAsset.Posters, OTT_POSTER_TYPE.Landscape)} style={{ borderRadius: "10px", height: "100%", left: "40%", top: 0, width: "60%", right: "0", display: "flex", position: "fixed", transition: "all 0.23s ease-in-out", filter: "blur(100px)" }} />
            <div style={{ borderRadius: "10px", height: "184px", left: "40%", top: positionTop, width: "60%", right: "20px", border: '3px solid white', display: "flex", position: "absolute", zIndex: 100000, transition: "all 0.23s ease-in-out" }}></div>
        </div>
        }
        <div ref={ref} style={{ borderRadius: "10px", marginBottom: "20px", marginRight: "20px", transition: "all 0.23s linear", display: "flex", position: "relative", border: '3px solid transparent', zIndex: 10000 }} key={ottAsset._id} >
            <img style={{ height: "180px", width: "300px", borderRadius: "10px", objectFit: "cover" }} src={utility.getPosterUrl(ottAsset.Posters, OTT_POSTER_TYPE.Landscape)} />
            {!isFree && <img style={{ height: "30px", width: "30px", position: "absolute", top: "5px", left: "318px", zIndex: "10000" }} src={crown} />}
            <div style={{ marginLeft: "20px", marginTop: "30px", color: "white" }}>
                <p style={{ fontSize: "larger", marginBottom: "2px" }}>{ottAsset.Title}</p>
                <p style={{ fontSize: "medium", marginBottom: "2px" }}>{utility.formatMilliseconds(ottAsset.Duration)}</p>
                <p style={{ fontSize: "medium", }}>{ottAsset.Description}</p>
            </div>
        </div>
    </FocusContext.Provider>
}

export default AssetCard;
