import moment from 'moment';
import React from 'react';
import logo from '../../../images/logo.png';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';

const EpgProgramCard = ({
    program,
    blockHeight,
    i,
    pixelPerMinute,
    CustomeFocusKey,
}) => {

    const onFocus = (props) => {
        console.log(props);
        let timelineDiv = document.getElementById('timeline');
        let wow = document.getElementById('wow');
        timelineDiv.scrollTo(props.x, 0);
        wow.scrollTo(props.x, 0);
    }

    const { ref, focused, focusKey } = useFocusable({
        focusKey: CustomeFocusKey,
        onFocus: onFocus,
    });

    let startTime = moment(program.startdatetime, "YYYY-MM-DDTHH:mm:ssZ").utc().toDate();
    var hours = startTime.getUTCHours() * 60;
    var minutes = startTime.getUTCMinutes();

    let totalMinutes = hours + minutes;

    let duration = moment(program.duration).utc().get('minute') - 1;
    let startPosition = totalMinutes * pixelPerMinute;
    let width = duration * pixelPerMinute;

    return <div ref={ref} style={{ width: width, height: blockHeight - 4, display: "inline-block", position: "absolute", zIndex: 1, backgroundColor: "#202936", top: i * blockHeight + 2 + 'px', left: startPosition + 5, color: "white", borderRadius: "5px", fontSize: "18px", opacity: 1 }}>
        <div style={{ display: "flex" }} title={program.title + " - " + moment(program.duration).utc().get('minute') + 'm'}>
            <img height={blockHeight - 4} width={"70px"} style={{ borderRadius: "5px", filter: "grayscale(100%)" }} src={logo} />
            <div style={{ paddingLeft: "10px", }}>
                <p style={{ opacity: 0.7 }}>{program.title}</p>
                <p style={{ fontSize: "16px", color: "grey" }}>{moment(program.duration).utc().get('minute') + 'm'}</p>
            </div>
        </div>
    </div>;
}

export default EpgProgramCard;
