import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useEffect } from 'react';
import FullKeypadNum from './fullkeypadNum';

const FullKeypad = ({ setSearchKey }) => {
    const { ref, focusKey, focusSelf, setFocus } = useFocusable({
        focusable: true,
        saveLastFocusedChild: true,
        trackChildren: true,
        autoRestoreFocus: true,
        isFocusBoundary: false,
        focusKey: "search",
        preferredChildFocusKey: null,
        onArrowPress: () => true,
    });

    useEffect(() => {
        focusSelf();

    }, [focusSelf]);

    useEffect(() => {
        setFocus("search")

    }, [setFocus]);

    const keypadButtons = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];


    return (
        <FocusContext.Provider value={focusKey}>
            <div className='keypadFullOuterDiv'>
                <ul id="keypadFull" ref={ref}>
                    {/* space */}
                    <FullKeypadNum key={'space'} numValue={<i className='material-symbols-outlined'>space_bar</i>} index={100} setSearchKey={setSearchKey} />
                    {/* backspace */}
                    <FullKeypadNum key={'backspace'} numValue={<i className='material-symbols-outlined'>backspace</i>} index={101} setSearchKey={setSearchKey} />
                    {/* abcd and numbers */}
                    {keypadButtons.map((button, index) => (
                        <FullKeypadNum key={button} numValue={button} index={index} setSearchKey={setSearchKey} />
                    ))}
                </ul>
            </div>
        </FocusContext.Provider>
    );
}

export default FullKeypad;
