import React, { useState } from 'react';
import 'material-symbols';

const BottomMenu = ({
    bottomMenu,
    ottSetting,
    changePage
}) => {

    const [selectedPage, setSelectedPage] = useState(0);

    return (
        <div style={{
            height: "70px", background: ottSetting?.themeData?.menu?.bgColor
                ?? "white", position: "fixed", bottom: 0, width: "100%", display: "flex", justifyContent: "space-evenly", alignItems: "center", color: ottSetting?.themeData?.menu.color
                    ?? "black",
            fontSize: ottSetting?.themeData?.menu?.fontSize ?? "15px", fontFamily: ottSetting?.themeData?.menu?.fontFamily ?? "Arial",
            zIndex: 10000
        }}>
            {
                bottomMenu?.map((item, index) => {
                    return (

                        <span key={index} onClick={() => { changePage(item._id); setSelectedPage(index) }} style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer", paddingBottom: "10px" }}>
                            <span className={`material-symbols-rounded`} style={{
                                fontSize: (parseInt(ottSetting?.themeData?.menu?.iconSize) + 5) + "px"
                                    ?? "25px",
                                color: selectedPage == index ? ottSetting?.themeData?.menu?.iconSelectedColor ?? "white" : ottSetting?.themeData?.menu?.iconColor ?? "white"
                            }} >
                                {item.IconUrl}
                            </span>
                            {item.displayName}
                        </span>
                    )
                })
            }
        </div>
    );
}

export default BottomMenu;
