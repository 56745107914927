import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useFocusable, FocusContext } from "@noriginmedia/norigin-spatial-navigation";
import { useEffect } from "react";
import ImageCard from "./ImageCard";
import MyButton from "./myButton";


const  ExitDailage = (props)=>{  
const { ref, focusKey,setFocus, focused } = useFocusable({focusKey:"exitbox"});
   

useEffect(()=>{

        if(props.openExitDialogue)
        {
          setFocus( 'NO_BUTTON' )
        }
        else
        {
            setFocus("CONTAINER")
        }

},[props.openExitDialogue])


return <>
<FocusContext.Provider value={focusKey}>
<Dialog
        open={props.openExitDialogue}
        onClose={props.onExitDialogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Exit Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to exit the app
          </DialogContentText>
        </DialogContent>
        <DialogActions ref={ref}>
         
        <MyButton title="Cancel" openExitDialogue={()=> {
            props.onExitDialogue(false)}}
            focusKeyRefrence={ 'NO_BUTTON' }
            ></MyButton>
          <MyButton title="Exit" openExitDialogue={()=> {
            window.close();
           }}
          
           
           >


           </MyButton>
        </DialogActions>
</Dialog>
</FocusContext.Provider>

</>;

}
export default ExitDailage;