import { PLATFORM } from "../constant";

const CMSURL = {
  BASEURL: "https://bmsuat.bossstudio.tv",
}

// BASEURL: "http://143.110.252.28:5000/v2/data/getLayout"

const GetHeader = (method = 'GET') => {
  return {
    method: method,
    accept: 'application/json',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6ImFkbWluIiwicGFzc3dvcmQiOiJhYmNkMTIzNCIsImlhdCI6MTY5NzYyNzExNCwiZXhwIjoxNzI5MTYzMTE0fQ.-sOyWMTk3FJ-UCcSiHy8H53zwjVZDIT4LXWqfywTDvM"
    },
  }
}

export async function getLayout() {

  try {
    var FINALURL = CMSURL.BASEURL + '/v2/data/getLayout';
    var Finalinit = { ...GetHeader() };
    console.log(FINALURL)
    const response = await fetch(FINALURL, Finalinit);
    console.log(response);
    const responseData = await response.json();

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function getPage(id) {

  try {
    var FINALURL = CMSURL.BASEURL + '/v2/ott/getPageById';
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify({ _id: id }) };
    console.log(FINALURL)
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    console.log(responseData);

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

export async function getEpg() {

  try {
    var FINALURL = CMSURL.BASEURL + '/v2/ott/getEPG';
    var Finalinit = { ...GetHeader('POST'), "body": JSON.stringify({ date: new Date().getTime() }) };
    console.log(FINALURL)
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    console.log(responseData);

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}


export async function getBottomMenu() {

  try {
    var FINALURL = CMSURL.BASEURL + '/v2/ott/getBottomMenu';
    var Finalinit = { ...GetHeader() };
    console.log(FINALURL)
    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();
    console.log(responseData);

    return responseData;
  }
  catch (error) {
    alert(error)
    console.log("error", error);
  }

}

//default args
var defaultArgs = {
  // MULTIPLE QUERRY EX: [ ['SID', '>', '0'], ['SID', '>', '0'], ['SID', '>', '0']  ]
  // SINGLE QUERRY EX: ['SID', '>', '0']
  query: [],
  // SORT ASC EX: { SID : 1 }
  // SORT DESC EX: { SID : -1 }
  // MULTIPLE SORTS EX: { SID : 1, DATE : -1 }
  sort: { SID: 1 },
  // 1 MEANS FIRST PAGE, 2 MEANS 2 PAGE
  page: null,
  // NO. OF ITEMS PER PAGE
  perPage: null,
  //ARCHIVE KEY
  archive: false
}

export async function getData(entityname, args = defaultArgs) {
  try {
    var finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,

    };
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    var FINALURL = CMSURL.BASEURL + '/v2/data/getEntities/' + entityname;
    // console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getOttSettings(args = defaultArgs) {
  try {
    var Finalinit = {
      ...GetHeader("POST"), "body": JSON.stringify(
        { platform: PLATFORM.filter(x => x.Description == "Web")[0].SID }
      )
    };
    var FINALURL = CMSURL.BASEURL + '/v2/ott/getOttSetting';
    // console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getAssetByID(id) {
  try {
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ _id: id }) };
    var FINALURL = CMSURL.BASEURL + '/v2/ott/getAssetById';
    // console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function getMoreMoviesLikeThis(genre) {
  try {
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ genre: genre }) };
    var FINALURL = CMSURL.BASEURL + '/v2/ott/getAssetsByGenre';
    // console.log(finalData);
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function sendOTP(mobileNumber) {
  try {
    var Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ "mobileNumber": mobileNumber }) };
    var FINALURL = CMSURL.BASEURL + '/v2/login/getOTP';
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}

export async function globalSearch(searchKey) {
  try {
    var Finalinit = { ...GetHeader("POST") };
    var FINALURL = `${CMSURL.BASEURL}/v2/ott/searchOttAssets/${searchKey.toString()}`;
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.log("error", error.message);
  }
}