import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import { useEffect, useState, useCallback, useRef } from 'react';
import { PLAYER, RAILTYPE } from '../constant';
import HeroBanner from './homePage/components/HeroBanner';
import Logo from './homePage/components/Logo';
import RailComponent from './homePage/components/RailComponent';
import TextComponent from './homePage/components/TextComponent';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ExitDailage from './homePage/components/exitDialoge';
import { useNavigate } from 'react-router-dom';
import PiPageTop from './homePage/components/PiPageTop';
import RailComponentNetflix from './homePage/components/RailComponentNetflix';
import BottomMenu from './homePage/components/bottomMenu';
import { getBottomMenu, getPage } from '../Api/api';
import TopMenu from './homePage/components/topMenu';

export const MainPage = (props) => {
    const { page, ottSetting, isMobile, setCurrentPage } = props;

    const { ref, focusKey, hasFocusedChild, focusSelf, setFocus } = useFocusable({
        focusable: isMobile ? false : true,
        saveLastFocusedChild: true,
        trackChildren: true,
        autoRestoreFocus: true,
        isFocusBoundary: false,
        focusKey: props.focusKeyParam,
        preferredChildFocusKey: null,
        onEnterPress: () => { },
        onEnterRelease: () => { },
        onArrowPress: () => true,
        onFocus: () => { },
        onBlur: () => { },
        extraProps: { foo: 'bar' }
    });

    const [selectedPage, setSelectedPage] = useState(page);

    useEffect(() => {
        focusSelf();

    }, [focusSelf]);

    useEffect(() => {
        setOttAsset(null);
        document.body.scrollTo(0, 0);
    }, [selectedPage, page]);

    useEffect(() => {
        setSelectedPage(page)
    }, [page]);

    useEffect(() => {
        if (isMobile) {
            loadBottomMenu();
        }
    }, [isMobile]);

    useEffect(() => {
        setFocus("CONTENT")

    }, [setFocus]);


    const [rails, setRails] = useState([]);
    const isHomeScreenRef = useRef(false);
    const [openExitDialogue, setOpenExitDialogue] = useState(false);
    const [ottAsset, setOttAsset] = useState();
    const [bottomMenu, setBottomMenu] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setRails(selectedPage.Rails?.filter((rails) => rails.PlatformSIDs.includes("1")) ?? []);
        isHomeScreenRef.current = false;
    }, [selectedPage, page]);

    useEffect(() => {
        document.addEventListener('keydown', onKeyPress);
        setFocus("CONTAINER");
        return () => document.removeEventListener('keydown', onKeyPress);
    }, []);

    const changePage = async (id) => {
        let res = await getPage(id);
        if (res.success) {
            setSelectedPage(res.data);
        }
    }

    const onExitDialogue = (state) => {

        if (state) {
            setFocus("exitbox");
        }
        else {
            setFocus("CONTAINER");
        }

        setOpenExitDialogue(state);
    };

    const onKeyPress = useCallback((e) => {
        const { keyCode } = e;

        if (keyCode === PLAYER.VK_BACK_SPACE || keyCode === PLAYER.VK_BACK || keyCode === PLAYER.BACK_LG || keyCode === PLAYER.RETURN) {
            e.preventDefault();
            if (isHomeScreenRef.current) {
                setOpenExitDialogue(true)
            } else {
                navigate(-1)
            }
        }

    });

    function getRail(rail, index) {

        switch (parseInt(rail.OttRailTypeSID)) {
            case RAILTYPE.FastChannel:
                setCurrentPage({ _id: 'epg' });
                return;
            case RAILTYPE.Logo:
                isHomeScreenRef.current = true;
                return <Logo key={6} asset={rail.OttAsset[0]} isMobile={false} />
            case RAILTYPE.TEXT:
                return <TextComponent key={7} label={rail.DisplayTitle} />
            case RAILTYPE.HeroBanner:
            case RAILTYPE.HeroBannerPotrait:
                if (isMobile) {
                    return <HeroBanner key={8} rail={rail} setOttAsset={setOttAsset} />
                } else {
                    return;
                }
            case RAILTYPE.Rail:
            case RAILTYPE.CircleRail:
            case RAILTYPE.PotraitRail:
            case RAILTYPE.LandscapeRail:
            case RAILTYPE.SquareRail:
                return <RailComponent setOttAsset={setOttAsset} ottSetting={ottSetting} rail={rail} onFocus={onRowFocus} index={index} key={index + "-" + rail.SID} rowIndex={"Rail" + index} railType={rail.OttRailTypeSID} isMobile={isMobile} />
            case RAILTYPE.TopCounter:
                return <RailComponent setOttAsset={setOttAsset} ottSetting={ottSetting} rail={rail} onFocus={onRowFocus} index={index} key={index + "-" + rail.SID} rowIndex={"Rail" + index} railType={rail.OttRailTypeSID} isMobile={isMobile} />
            default:
                return <RailComponent setOttAsset={setOttAsset} ottSetting={ottSetting} rail={rail} onFocus={onRowFocus} index={index} key={index + "-" + rail.SID} rowIndex={"Rail" + index} railType={rail.OttRailTypeSID} isMobile={isMobile} />
        }
    }


    const onRowFocus = useCallback(({ y, top, ...rest }) => {

        console.log("calling row focus " + y);
        console.log(top);
        console.log(window.scrollY);

        // ref.current?.scrollTo({
        //     top: top,
        //     behavior: 'smooth'
        // })

        document.getElementById('contentDiv').scrollTo({
            top: top - window.innerHeight / 2,
            behavior: "smooth",
        });

    }, [ref]);
    var theme = {
        bgColor: ottSetting?.themeData?.general?.bgColor ?? "black",
        color: ottSetting?.themeData?.general?.color ?? "white",
        primaryColor: {
            bgColor: ottSetting?.themeData?.primary?.bgColor,
            color: ottSetting?.themeData?.primary?.color,
        },
        secondaryColor: {
            bgColor: ottSetting?.themeData?.secondary?.bgColor,
            color: ottSetting?.themeData?.secondary?.color,
        }


    }

    const loadBottomMenu = async () => {
        let res = await getBottomMenu();
        console.log(res);
        if (res.success) {
            setBottomMenu(res.data.Pages ?? []);
        }
    }

    return <>
        {isMobile && <TopMenu ottSetting={ottSetting} />}
        {isMobile && <BottomMenu ottSetting={ottSetting} bottomMenu={bottomMenu} changePage={changePage} />}
        <div className={hasFocusedChild ? 'ContentWrapper' : 'ContentWrapper'} style={{ backgroundColor: props?.ottSetting?.themeData?.general?.bgColor ?? "black" }}>

            {/* pi page */}
            <div ref={ref} style={{ position: "fixed", height: "100vh", top: 0, zIndex: 100, opacity: ottAsset ? 1 : 0, transition: "all 0.5s", }}> {!isMobile && ottAsset && <PiPageTop ottAsset={ottAsset} theme={theme} showPlayButton={false} />}</div>
            <FocusContext.Provider value={focusKey}>
                {/* rails */}
                <div id='contentDiv' className='ScrollingRows' style={{ height: ottAsset ? "50vh" : "auto", width: "100%", transition: "all 0.23s ease", paddingBottom: "500px", overflow: "scroll", marginTop: ottAsset ? "50vh" : 0, zIndex: 101, }}>

                    {rails.map((rail, index) => {
                        return getRail(rail, index);
                    })}


                </div>
            </FocusContext.Provider>

        </div>

        {openExitDialogue && <ExitDailage openExitDialogue={openExitDialogue} onExitDialogue={onExitDialogue}></ExitDailage>}
    </>
        ;


}





export default MainPage;


