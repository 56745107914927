import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { utility } from '../../../utility/utility';
import { OTT_POSTER_TYPE } from '../../../constant';

const PiPageTop = ({ ottAsset, theme, showPlayButton = true, ottSetting, isMobile }) => {
    const [image, setImage] = useState(ottAsset?.Posters ? utility.getPosterUrl(ottAsset?.Posters, OTT_POSTER_TYPE.Landscape) : '');
    useEffect(() => {
        // Update the image URL when ottAsset changes
        setImage(ottAsset?.Posters ? utility.getPosterUrl(ottAsset?.Posters, OTT_POSTER_TYPE.Landscape) : '');
    }, [ottAsset]);
    console.log(ottAsset);
    const piPageSetting = ottSetting?.piPageSetting;

    return (
        <div className='pi-page' style={{ backgroundColor: theme?.bgColor ?? "black" }} >
            {!isMobile && <div className='imageOverlay' style={{ backgroundImage: `url(${image})`, boxShadow: `100px 0 100px 0 black inset`, borderBottomLeftRadius: "70%" }}> </div>}
            {!isMobile && <div className='imageOverlayBlur' style={{ backgroundImage: `url(${image})` }}> </div>}
            <div className='pi-page-main' style={{ color: theme.color, zIndex: 100000 }}>
                {/* media category example movie */}
                <p className='type'>{utility.getMediaCategoryType(ottAsset?.MediaCategory ?? 0)}</p>
                {/* title */}
                <p className='title'>{((piPageSetting?.provider ?? true) && ottAsset?.Provider?.LogoUrl) && <img src={ottAsset?.Provider?.LogoUrl ?? 'https://res.cloudinary.com/dl2n34gbw/image/upload/v1707321508/gswygsxknleqqxjxqyrt.jpg'} style={{ width: "30px", height: "30px", borderRadius: "50%", objectFit: "cover", backgroundColor: "white" }} />} {ottAsset?.Title ?? ""}</p>
                {/* genre action, thriller */}
                {((piPageSetting?.genre ?? true) && ottAsset?.Genres?.length != 0) && <span className='genre'>{ottAsset?.Genres?.toString() ?? ""}</span>}
                {/* release date 2024 */}
                {((piPageSetting?.releaseDate ?? true) && ottAsset?.ReleaseDate.toString().length > 0 && ottAsset?.ReleaseDate != 0) && <span className='year'>{new Date(ottAsset?.ReleaseDate.toString().length > 0 ? ottAsset?.ReleaseDate : 0).getFullYear()}</span>}
                {/* pg rating PG18 */}
                {((piPageSetting?.pgRating ?? true) && ottAsset?.PGRating != 'N/A' && ottAsset?.PGRating?.toString().length != 0) && <span className='rating'>{ottAsset?.PGRating ?? ""}</span>}
                {/* duration 1h 30m */}
                {ottAsset?.Duration != 0 && <span className='duration'>{new Date(ottAsset?.Duration ?? 0).getUTCHours()}h {new Date(ottAsset?.Duration ?? 0).getUTCMinutes()}m</span>}
                {/* description */}
                {(piPageSetting?.description ?? true) && <p style={{ marginTop: showPlayButton ? "60px" : 0 }} className='description'>{ottAsset?.MediaEpisodeDescription?.length > 0 ? ottAsset?.MediaEpisodeDescription : ottAsset?.Description ?? ""}</p>}
                {/* cast & crew */}
                {((piPageSetting?.genre ?? true) && ottAsset?.CastAndCrew?.length != 0) && <span className='description' style={{
                    opacity: 0.8, fontSize: "12px"
                }}>{ottAsset?.CastAndCrew.map(c => `${c.CastType} : ${c.Name}`)?.toString().substring(0, 100) ?? ""}</span>}
                {/* languages hindi, english */}
                {((piPageSetting?.genre ?? true) && ottAsset?.Languages?.length != 0) && <span className='description' style={{
                    opacity: 0.9, fontSize: "12px"
                }}><i className='fa fa-bullhorn' /> {ottAsset?.Languages?.toString().substring(0, 100) ?? ""}</span>}

            </div>
        </div>
    );
}

export default PiPageTop;
