import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OTT_POSTER_TYPE, PLAYER } from '../../../constant';
import { globalSearch } from '../../../Api/api';
import { utility } from '../../../utility/utility';
import AssetCard from './AssetCard';

const SearchRightSide = ({ searchKey }) => {
    const navigate = useNavigate();

    const [searchResults, setSearchResults] = useState([]);
    const [positionTop, setPositionTop] = useState(0);
    var timeout = null;

    useEffect(() => {
        if (searchKey.toString().trim().length > 0) {
            search();
        } else {
            setSearchResults([]);
            setMessage('Search for your favorite movies and shows');
        }
    }, [searchKey]);

    useEffect(() => {
        document.addEventListener('keydown', onBack);
        return () => {
            document.removeEventListener('keydown', onBack);
        }
    }, []);

    const [message, setMessage] = useState('');

    const onBack = (e) => {
        const { keyCode } = e;

        if (keyCode === PLAYER.VK_BACK_SPACE || keyCode === PLAYER.VK_BACK || keyCode === PLAYER.BACK_LG || keyCode === PLAYER.RETURN) {
            e.preventDefault();
            navigate(-1);
        }
    }

    const search = async () => {
        let res = await globalSearch(searchKey);
        if (res.success) {
            if (res.data.length > 0) {
                setSearchResults(res.data);
                setMessage('');
            } else {
                setSearchResults([]);
                setMessage('No result found');
            }
        } else {
            console.log(res);
            setSearchResults([]);
            setMessage(res.message);
        }
    }

    useEffect(() => {
        if (positionTop < 115) {
            setPositionTop(106);
        }
    }, [positionTop]);

    return (
        <div className='searchRightSideOuterDiv' >
            <div style={{ color: searchKey.trim().length == 0 ? 'grey' : 'white', fontSize: '24px', fontWeight: 'bold', borderRadius: "10px", marginTop: "20px", height: "70px", marginRight: "20px", borderBottom: "2px solid white", lineHeight: "70px", paddingLeft: "20px" }}>
                {searchKey.trim().length == 0 ? "Search for your favorite movies and shows" : searchKey}
            </div>

            {searchResults.length > 0 && <div id='searchRightSideContainer' style={{ height: "calc(100% - 70px)", overflow: "hidden", paddingTop: "15px" }} >
                {searchResults.map((ottAsset, index) => {
                    return <AssetCard timeout={timeout} positionTop={positionTop} setPositionTop={setPositionTop} index={index * 200} ottAsset={ottAsset} />
                })}
            </div>
            }
            {message.length > 0 && <div style={{
                color: 'white',
                width: "100%",
                height: "calc(100% - 70px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>{message}</div>}
        </div>
    );
}

export default SearchRightSide;
