import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React from 'react';

const KeypadNum = ({ key, numValue, index, setMobileNumber, showVerifyOTP }) => {
    const { ref, focused } = useFocusable({
        focusKey: key,
        onEnterRelease: () => {
            if (numValue === 'x') {
                setMobileNumber('');
            } else if (numValue === '←') {
                setMobileNumber(mobileNumber => mobileNumber.slice(0, -1));
            } else {
                setMobileNumber(mobileNumber => {
                    if (showVerifyOTP && mobileNumber.length == 4) {
                        return mobileNumber;
                    } else if (!showVerifyOTP && mobileNumber.length == 10) {
                        return mobileNumber;
                    }
                    return mobileNumber + numValue
                });
            }
        }

    });
    return (
        <li
            ref={ref}
            key={key}
            className={`letter ${index % 3 === 0 && index !== 0 ? 'clearl' : ''} ${focused ? 'active' : ''}`}
        > {numValue}
        </li>
    );
}

export default KeypadNum;
