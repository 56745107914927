import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useState } from 'react';
import FullKeypad from './fullkeypad';
import SearchRightSide from './searchRightSide';

const SearchPage = () => {
    const { ref, focusKey } = useFocusable();

    const [searchKey, setSearchKey] = useState('');

    return (
        <FocusContext.Provider value={focusKey}>
            <div id="searchPage" ref={ref} style={{ overflow: "hidden" }} >
                <div id='searchContent'>
                    {/* Pass the focusKeyParam prop to Keypad */}
                    <FullKeypad setSearchKey={setSearchKey} />
                    {/* Enter mobile number */}
                    <SearchRightSide searchKey={searchKey} />
                </div>
            </div>
        </FocusContext.Provider>
    );
}

export default SearchPage;
