import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import SwiperCore, {
  Autoplay, Pagination, EffectFade
} from 'swiper';
import { utility } from "../../../utility/utility";
import { OTT_POSTER_TYPE, RAILTYPE } from "../../../constant";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";

const HeroBanner = (props) => {
  const { ref } = useFocusable({
    onFocus: () => {
      // scroll to top
      props.setOttAsset(null);
      ref.current.scrollTo(0, 0);
    }
  });
  const isPotrait = props.rail.OttRailTypeSID == RAILTYPE.HeroBannerPotrait;
  return <div ref={ref}>

    <Swiper
      pagination={{
        type: 'bullets',
        clickable: 'true'
      }}
      modules={[Pagination, Autoplay, EffectFade]}
      slidesPerView={1}
      spaceBetween={500}
      centeredSlides={true}
      speed={500}
      direction='horizontal'
      className='mySwiper'
      keyboard={{
        enabled: true
      }}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false
      }}
    >
      {props.rail.OttAsset.map((asset, index) => {
        return <SwiperSlide>
          <div className="heroBannerContainer" style={{ padding: isPotrait ? "0 70px" : "0 30px", height: isPotrait ? "60vh" : "auto", background: "transparent" }}>
            <img src={utility.getPosterUrl(asset.Posters, isPotrait ? OTT_POSTER_TYPE.Portrait : OTT_POSTER_TYPE.Landscape)} className="herobanner" alt="First slide" style={{ aspectRatio: isPotrait ? "2/3" : "16/9", objectFit: "cover", }} />
          </div>

        </SwiperSlide>
      })}


    </Swiper>


  </div>

}

export default HeroBanner;