import { FocusContext, useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useCallback, useEffect, useRef, useState } from "react";
import { LOCAL_STORAGE_KEY, PLAYER } from "../../../constant";
import { getPage } from "../../../Api/api";
import 'material-symbols';
import { utility } from "../../../utility/utility";
import { useNavigate } from "react-router";
import crown from '../../../images/crown.png';
function MenuItem({ hasFocusedChild, menuSettings, item, index, setSelectedMenu, currentPage }) {
    const { ref, focused } = useFocusable();
    console.log(currentPage)
    //if focused
    useEffect(() => {
        if (focused) {
            setSelectedMenu(item);
        }
    }, [focused]);

    let isSelectedMenu = currentPage._id == item._id;
    return <div key={index} style={{
        width: "auto",
        height: "51px",
        backgroundColor: "transparent",
        borderColor: "white",
        borderStyle: "solid",
        borderWidth: `${focused ? '2px' : 0}`,
        boxSizing: "border-box",
        borderRadius: "7px",
        marginBottom: "37px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }} ref={ref} focused={focused} ><span className={`material-symbols-outlined`} style={{
        fontSize: (parseInt(menuSettings?.iconSize) + 7) + "px"
            ?? "25px", color: isSelectedMenu ? menuSettings?.iconSelectedColor : menuSettings?.iconColor ?? "white"
    }} >{item.IconUrl}</span> {hasFocusedChild && <span style={{ color: isSelectedMenu ? menuSettings?.iconSelectedColor : menuSettings?.color ?? "white", marginLeft: "10px" }}>{item?.displayName ?? ""}</span>}</div>;
}

const NmLogo = ({ src, width, showCrown }) => <div>{showCrown && <img className={"crown focus"} src={crown} />}<img src={src} style={{
    width: width * 7,
    marginBottom: " 10px",
    padding: "0 10px",
    objectFit: "contain",
}} /></div>;


export default function Menu({ focusKey: focusKeyParam, ottSetting, menu, setCurrentPage, currentPage }) {
    const {
        ref,
        focusSelf,
        hasFocusedChild,
        focusKey
        // setFocus, -- to set focus manually to some focusKey
        // navigateByDirection, -- to manually navigate by direction
        // pause, -- to pause all navigation events
        // resume, -- to resume all navigation events
        // updateAllLayouts, -- to force update all layouts when needed
        // getCurrentFocusKey -- to get the current focus key
    } = useFocusable({
        focusable: true,
        saveLastFocusedChild: false,
        trackChildren: true,
        autoRestoreFocus: true,
        isFocusBoundary: false,
        focusKey: focusKeyParam,
        preferredChildFocusKey: null,
        onArrowPress: () => true,
    });
    const selectedMenu = useRef({});
    const isLoginRef = useRef(utility.getValue(LOCAL_STORAGE_KEY.user) !== null);
    const setSelectedMenu = (menu) => {
        selectedMenu.current = menu;
    }
    const navigate = useNavigate();

    useEffect(() => {
        focusSelf();
    }, [focusSelf]);

    useEffect(() => {
        if (hasFocusedChild) {
            window.addEventListener('keydown', onKeyPress);
        } else {
            window.removeEventListener('keydown', onKeyPress)
        }

        return () => window.removeEventListener('keydown', onKeyPress);
    }, [hasFocusedChild]);

    const onKeyPress = useCallback(async ({ keyCode }) => {

        if (keyCode === PLAYER.ENTER) {
            if (selectedMenu.current._id == "logout" || selectedMenu.current._id == "login") {
                utility.deleteValue(LOCAL_STORAGE_KEY.user);
                navigate('/login');
                return;
            }
            if (selectedMenu.current._id == "search") {
                navigate('/search');
                return;
            }
            // if (selectedMenu.current._id == "epg") {
            //     setCurrentPage(selectedMenu.current);
            //     return;
            // }
            let res = await getPage(selectedMenu.current._id);
            if (res.success) {
                setCurrentPage(res.data);
            } else {
                console.log("Page not found");
            }

        }

    });

    return (
        <FocusContext.Provider value={focusKey}>
            <div style={{
                flex: 1,
                maxWidth: hasFocusedChild ? "250px" : "75px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: ottSetting?.menu?.bgColor ?? "black",
                paddingTop: "37px",
                overflow: "hidden",
                position: "relative",
                transition: "all 0.23s ease-in-out",
            }} ref={ref} hasFocusedChild={hasFocusedChild}>
                <div style={{ position: "fixed" }}>
                    {hasFocusedChild && <NmLogo src={ottSetting?.logo ?? ""} width={ottSetting?.logoSize ?? 25} showCrown={
                        utility.getValue(LOCAL_STORAGE_KEY.user)?.PaymentPlan?.length > 0 ? true : false ?? false
                    } />}
                    {/* Welcome Guest */}
                    {hasFocusedChild &&
                        <p style={{ color: "white", fontSize: "15px", marginTop: "25px", marginBottom: "25px", textAlign: "center" }}>{isLoginRef.current ? "Welcome " + utility.getValue(LOCAL_STORAGE_KEY.user)?.Name ?? "" : "Welcome Guest"}</p>}
                    {/* search */}
                    <MenuItem key={menu.length + 1} hasFocusedChild={hasFocusedChild} menuSettings={ottSetting.themeData.menu} item={{ _id: "search", displayName: "Search", IconUrl: "search" }} index={menu.length} setSelectedMenu={setSelectedMenu} currentPage={currentPage} />
                    {/* menu itemes */}
                    {menu.map((item, index) => (
                        <MenuItem key={index} hasFocusedChild={hasFocusedChild} menuSettings={ottSetting.themeData.menu} item={item} index={index} setSelectedMenu={setSelectedMenu} currentPage={currentPage} />
                    ))}
                    {/* <MenuItem key={menu.length + 3} hasFocusedChild={hasFocusedChild} menuSettings={ottSetting.themeData.menu} item={{ _id: "epg", displayName: "EPG", IconUrl: "tv" }} index={menu.length + 1} setSelectedMenu={setSelectedMenu} currentPage={currentPage} /> */}
                    <MenuItem key={menu.length + 2} hasFocusedChild={hasFocusedChild} menuSettings={ottSetting.themeData.menu} item={{ _id: isLoginRef.current ? "logout" : "login", displayName: isLoginRef.current ? "Logout" : "Login", IconUrl: isLoginRef.current ? "logout" : "login" }} index={menu.length + 1} setSelectedMenu={setSelectedMenu} currentPage={currentPage} />
                    {/* epg*/}
                </div>
            </div>
        </FocusContext.Provider >
    );
}